export type TimezoneResponse = {
  dstOffset: number
  rawOffset: number
  status: string
  timeZoneId: string
  timeZoneName: string
}

const _useDateTimeInput = () => useState<string>('dateTimeInput', () => '')
const _useDurationInput = () => useState<number | null>('durationInput', () => null)
const _useTimeZone = () => useState<TimezoneResponse | null>('timeZone', () => null)
const _useSecondInput = () => useState<number | null>('secondInput', () => null)
const _useMinuteInput = () => useState<number | null>('minuteInput', () => null)
const _useHourInput = () => useState<number | null>('hourInput', () => null)

const _dateTimeDurationInputValid = computed(() => {
  const dateTimeInput = _useDateTimeInput()
  const durationInput = _useDurationInput()
  return dateToIsoLocal(new Date(dateTimeInput.value)).length > 0 && durationInput.value !== null && Number(durationInput.value) > 0
})

const _dateTimeInputValid = computed(() => {
  const max = dateToIsoLocal(new Date()).slice(0, 16)
  const dateTimeInput = _useDateTimeInput()
  return dateToIsoLocal(new Date(dateTimeInput.value)).slice(0, 16) <= max
})

export default function () {
  return {
    dateTimeInput: _useDateTimeInput(),
    durationInput: _useDurationInput(),
    timeZone: _useTimeZone(),
    inputValid: _dateTimeDurationInputValid,
    dateTimeInputValid: _dateTimeInputValid,
    secondInput: _useSecondInput(),
    minuteInput: _useMinuteInput(),
    hourInput: _useHourInput()
  }
}
