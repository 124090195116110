const _useSlideIndex = () => useState<number>('slideIndex', () => 0)
const _totalSlides = 12
const _termsAgreement = () => useState<boolean>('termsAgreement', () => false)

const _invalidSlideIndices = computed(() => {
  const total = _totalSlides
  const invalidIndices = []
  for (let i = 1; i < total - 2; i++) {
    if (!slideIsValid(i)) {
      invalidIndices.push(i)
    }
  }
  return invalidIndices
})

const _completedSlideIndices = computed(() => {
  const total = _totalSlides
  const completedIndices = []
  for (let i = 0; i < total - 2; i++) {
    if (slideIsValid(i) && _useSlideIndex().value !== i) {
      completedIndices.push(i)
    }
  }
  return completedIndices.length
})

const _allSlidesValid = computed(() => {
  const total = _totalSlides
  for (let i = 1; i < total - 2; i++) {
    if (!slideIsValid(i)) {
      return false
    }
  }
  return true
})

function _nextSlide() {
  const idx = _useSlideIndex()
  if (idx.value < _totalSlides - 2) { idx.value++ }
}

function _prevSlide() {
  const idx = _useSlideIndex()
  if (idx.value > 0) { idx.value-- }
}

function _setActiveSlide(newIdx: number) {
  const idx = _useSlideIndex()
  idx.value = newIdx
}

export default function () {
  return {
    activeSlide: _useSlideIndex(),
    totalSlides: _totalSlides,
    nextSlide: _nextSlide,
    prevSlide: _prevSlide,
    setActiveSlide: _setActiveSlide,
    allSlidesValid: _allSlidesValid,
    invalidSlideIndices: _invalidSlideIndices,
    completedSlideIndices: _completedSlideIndices,
    termsAgreement: _termsAgreement()
  }
}
