import log from 'consola'
import { v4 as uuidv4 } from 'uuid'
import { Craft_Shape_Enum_Enum, Incident_Insert_Input, Incident_Survey_Insert_Input, Locations_Insert_Input, Web_Submitter_Insert_Input } from '~/utils/generated/graphql'

const _incidentId = () => useState('incidentId', () => { return uuidv4() })
const _locationId = () => useState('locationId', () => { return uuidv4() })
const _surveyId = () => useState('surveyId', () => { return uuidv4() })

const _incidentInsertInput = computed<Incident_Insert_Input | null>(() => {
  const id = _incidentId().value
  const locationId = _locationId().value

  return {
    id,
    location_id: locationId,
    data_source_id: 'ENIGMA_WEB'
  }
})

const _craftShapeInsertInput = computed<Craft_Shape_Enum_Enum | null>(() => {
  const behaviorInsertInput = useBehavior().insertInput()
  const selectedShape = behaviorInsertInput.features[1].selected

  // if user enters custom input
  if (typeof selectedShape !== 'string') {
    return null
  }

  const index = Object.values(Craft_Shape_Enum_Enum).indexOf(selectedShape.toUpperCase() as unknown as Craft_Shape_Enum_Enum)
  const key = Object.keys(Craft_Shape_Enum_Enum)[index]

  return Craft_Shape_Enum_Enum[key as keyof typeof Craft_Shape_Enum_Enum]
})

const _surveyInsertInput = computed<Incident_Survey_Insert_Input | null>(() => {
  const locationInsert = _locationInsertInput
  const submitter = _webSubmitterInsertInput
  const craftShape = _craftShapeInsertInput
  const { witnessCountInput } = useWitnessCountSlide()
  const { dateTimeInput, durationInput } = useDateTimeSlide()
  const { storyInput } = useStorySlide()
  const behaviorInsertInput = useBehavior().insertInput()

  const locationId = _locationId().value
  const incidentId = _incidentId().value
  const surveyId = _surveyId().value
  const craftShapeEnum = craftShape.value

  if (locationInsert.value === null) {
    log.error('Location insert is null')
    return null
  }

  if (witnessCountInput === null) {
    log.error('Witness count input is null')
    return null
  }

  if (dateTimeInput === null) {
    log.error('Date time input is null')
    return null
  }

  if (durationInput === null) {
    log.error('Duration input is null')
    return null
  }

  if (storyInput === null) {
    log.error('Story description input is null')
    return null
  }

  if (submitter.value === null) {
    log.error('Submitter is null')
    return null
  }

  return {
    id: surveyId,
    craft_shape: craftShapeEnum,
    location_id: locationId,
    incident_id: incidentId,
    witness_count: witnessCountInput.value,
    started_at: dateTimeInput.value,
    duration: durationInput.value,
    description: storyInput.value,
    web_submitters: { data: [submitter.value] },
    behavior: behaviorInsertInput
  }
})

const _locationInsertInput = computed<Locations_Insert_Input | null>(() => {
  const { markerLocation, geocodeSelection } = useLocationSlide()
  const id = _locationId().value

  if (markerLocation.value === null) {
    return null
  }

  return {
    id,
    center: {
      type: 'Point',
      coordinates: [markerLocation.value.lng, markerLocation.value.lat]
    },
    name: geocodeSelection.value?.place_name,
    mapbox_response: geocodeSelection.value
  }
})

const _webSubmitterInsertInput = computed<Web_Submitter_Insert_Input | null>(() => {
  const { expertiseOptions } = useSensorSlide()
  const { fingerprint } = useFingerprint()

  return {
    profession_list: expertiseOptions.value
      .filter(f => f.selected)
      .map(f => f.description)
      .join(', '),
    visitor_id: fingerprint.value
  }
})

export default function () {
  return {
    surveyInsertInput: _surveyInsertInput,
    incidentInsertInput: _incidentInsertInput,
    locationInsertInput: _locationInsertInput,
    incidentId: _incidentId(),
    locationId: _locationId(),
    surveyId: _surveyId()
  }
}
