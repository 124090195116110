/// <reference types="mapbox-gl" />
import { Result } from '@mapbox/mapbox-gl-geocoder'
import { ReverseGeocodeResult } from '../use-geocode'

const _useLocationInput = () => useState<string>('locationInput', () => '')
const _useGeocodeSelection = () => useState<Result & any | null>('geocodeSelection', () => null)
const _useMarkerLocation = () => useState<mapboxgl.LngLat | null>('markerLocation', () => null)

const _useGeocodeId = () => useState<string | null>('geocodeId', () => null)
const _useReverseGeocodeResult = () => useState<ReverseGeocodeResult | null>('reverseGeocodeResult', () => null)
const _useYesOrNoPII = () => useState<string>('yesOrNoPII', () => '')
const _useAddressText = () => useState<string | null>('addressText', () => null)

const _locationInputValid = computed(() => {
  const markerLocation = _useMarkerLocation()
  const selectedId = _useGeocodeId()
  const reverseResult = _useReverseGeocodeResult()
  return markerLocation.value !== null && (
    (selectedId.value === null && reverseResult.value?.features.length === 0) ||
    (selectedId.value !== null && reverseResult.value?.features !== undefined && reverseResult.value?.features.length > 0)
  )
})

export default function () {
  return {
    locationInput: _useLocationInput(),
    geocodeSelection: _useGeocodeSelection(),
    markerLocation: _useMarkerLocation(),
    inputValid: _locationInputValid,
    geocodeId: _useGeocodeId(),
    reverseGeocodeResult: _useReverseGeocodeResult(),
    yesOrNoPII: _useYesOrNoPII(),
    addressText: _useAddressText()
  }
}
