import expertiseOptions from '~/assets/expertise.json'
import sensorOptions from '~/assets/sensor.json'
// import { Sensor_Enum } from '~/utils/generated/graphql'

// const _sensorOptions = () => useState('sensors', () => {
//   return Object.keys(Sensor_Enum)
//     .filter((item) => {
//       return isNaN(Number(item))
//     })
//     .map(id => {
//       return {
//         id,
//         selected: false,
//         description: id.toLowerCase()
//       }
//     })
//     .sort((a, b) => a.description.localeCompare(b.description))
// })
const _otherSensorInput = () => useState<string>('otherSensorInput', () => '')
const _sensorOptions = ref(sensorOptions.sort((a, b) => a.description.localeCompare(b.description)))
const _expertiseOptions = ref(expertiseOptions.sort((a, b) => a.description.localeCompare(b.description)))
const _noSensorInput = () => useState<boolean>('noSensor', () => false)
const _noProfessionInput = () => useState<boolean>('noProfession', () => false)

const _inputValid = computed(() => {
  const noSensor = _noSensorInput().value
  const noProfession = _noProfessionInput().value

  if (_otherSensorInput().value.length > 0 && (_expertiseOptions.value.some(o => o.selected) || noProfession)) {
    return true
  }

  return (_sensorOptions.value.some(o => o.selected) || noSensor) &&
    (_expertiseOptions.value.some(o => o.selected) || noProfession)
})

export default function () {
  return {
    otherSensorInput: _otherSensorInput(),
    sensorOptions: _sensorOptions,
    expertiseOptions: _expertiseOptions,
    noSensorInput: _noSensorInput(),
    noProfessionInput: _noProfessionInput(),
    inputValid: _inputValid
  }
}
