const _nid = () => useState<number | null>('nid', () => null)
const _webSubmitterId = () => useState<string | null>('webSubmitterId', () => null)
const _phoneNumberInput = () => useState<string | null>('phoneNumberInput', () => null)
const _emailInput = () => useState<string | null>('emailInput', () => null)
const _phoneNumberValid = () => useState<boolean>('phoneNumberValid', () => false)
const _phoneNumberFull = () => useState<string>('phoneNumberFull', () => '')
const _smsOptIn = () => useState<boolean>('smsOptIn', () => false)

export default function () {
  return {
    nid: _nid(),
    phoneNumberInput: _phoneNumberInput(),
    emailInput: _emailInput(),
    webSubmitterId: _webSubmitterId(),
    phoneNumberValid: _phoneNumberValid(),
    phoneNumberFull: _phoneNumberFull(),
    smsOptIn: _smsOptIn()
  }
}
