import axios from 'axios'
import { MediaUploadCompleteDocument, MediaUploadCompleteMutation, MediaUploadCompleteMutationVariables, PutUrlForMediaDocument, PutUrlForMediaQuery, PutUrlForMediaQueryVariables } from '~/utils/generated/graphql'
export interface IUploadableFile {
  id: string
  file: File
  url: string
  type: 'audio' | 'video' | 'image' | null
  uploadStatus: 'pending' | 'authenticating' | 'uploading' | 'complete' | 'error'
  putUrl: string | null
  fetchUrl: string | null
  percentComplete: number

  upload(): Promise<void>
  uploadWithToken(token: string | null): Promise<void>
}

const _useFiles = () => useState<IUploadableFile[]>('files', () => [])

function _addFile(newFile: File) {
  const files = _useFiles()
  const uploadable = new UploadableFile(newFile)
  const newUploadableFiles = [uploadable]
    .filter(file => !_fileExists(file.id))
  files.value = files.value.concat(newUploadableFiles)
  _logFiles(newUploadableFiles)

  return uploadable
}

function _addFiles(newFiles: FileList) {
  const files = _useFiles()
  const newUploadableFiles = [...newFiles]
    .map(file => new UploadableFile(file))
    .filter(file => !_fileExists(file.id))
  files.value = files.value.concat(newUploadableFiles)
  _logFiles(newUploadableFiles)
}

function _logFiles(files: Array<UploadableFile>) {
  const { $sentryAddAttachment } = useNuxtApp()
  files.forEach((f) => {
    f.file.arrayBuffer()
      .then(ab => new Uint8Array(ab))
      .then((u8a) => {
        $sentryAddAttachment({
          filename: f.file.name, contentType: f.file.type, data: u8a
        })
        log.trace(`Added ${f.file.type} file as Sentry attachment`)
      })
  })
}

function _fileExists(otherId: string) {
  const files = _useFiles()
  return files.value.some(({ id }) => id === otherId)
}

function _removeFile(file: IUploadableFile) {
  const files = _useFiles()
  const index = files.value.indexOf(file)

  if (index > -1) { files.value.splice(index, 1) }
}

const _audioFiles = computed(() => {
  const files = _useFiles()
  return files.value.filter(file => file.type === 'audio')
})

const _invalidFiles = computed(() => {
  const files = _useFiles()
  return files.value.filter(file => file.type === null)
})

const _validFiles = computed(() => {
  const files = _useFiles()
  return files.value.filter(file => file.type !== null)
})

export default function () {
  return {
    files: _useFiles(),
    addFile: _addFile,
    addFiles: _addFiles,
    fileExists: _fileExists,
    removeFile: _removeFile,
    invalidFiles: _invalidFiles,
    validFiles: _validFiles,
    audioFiles: _audioFiles
  }
}

export class UploadableFile implements IUploadableFile {
  id: string
  file: File
  url: string
  type: 'audio' | 'video' | 'image' | null
  uploadStatus: 'pending' | 'authenticating' | 'uploading' | 'complete' | 'error' = 'pending'
  putUrl: string | null = null
  fetchUrl: string | null = null
  mediaId: string | null = null
  percentComplete = 0

  constructor(file: File) {
    this.file = file
    this.id = `${file.name}-${file.size}-${file.lastModified}-${file.type}`
    this.url = URL.createObjectURL(file)
    this.type = this._fileType()
  }

  public async uploadWithToken(token: string | null) {
    const { $sentryCaptureException } = useNuxtApp()
    const mediaUploadUrl = useRuntimeConfig().public.mediaUploadUrl

    this.uploadStatus = 'authenticating'
    this.mediaId = token
    this.uploadStatus = 'uploading'

    try {
      const upload = await axios.post(mediaUploadUrl, {
        file: this.file,
        mediaId: this.mediaId
      }, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total!)
          this.percentComplete = percentCompleted
        }
      })

      if (upload.data.uploaded) {
        this.uploadStatus = 'complete'
      }
    } catch (error) {
      log.error(error)
      $sentryCaptureException(error)
      this.uploadStatus = 'error'
      throw new Error('Upload failed')
    }
  }

  public async upload() {
    this.uploadStatus = 'authenticating'
    const putUrl = await this.getPutUrl()
    this.uploadStatus = 'uploading'

    const upload = await $fetch.raw<string>(putUrl, { method: 'PUT', body: this.file, headers: { "content-type": "application/octet-stream" } })
    if (upload.status !== 200) {
      log.error(upload)
      this.uploadStatus = 'error'
      throw new Error('Upload failed')
    }
    await this.uploadCompleteMutation()
    this.uploadStatus = 'complete'
  }

  private async uploadCompleteMutation() {
    if (this.mediaId === null) { throw new Error('No media id') }
    const { mutate, error } = useMutation<MediaUploadCompleteMutation, MediaUploadCompleteMutationVariables>(MediaUploadCompleteDocument, {
      variables: {
        id: this.mediaId
      }
    })

    const result = await mutate()
    if (result === null) { throw new Error('No result from mutation') }
    if (error.value) { throw error }

    return result.data
  }

  private async getPutUrl(): Promise<string> {
    const { surveyId } = useInsertInput()
    const { fingerprint } = useFingerprint()
    const { mutate, error } = useMutation<PutUrlForMediaQuery, PutUrlForMediaQueryVariables>(PutUrlForMediaDocument, {
      variables: {
        filename: this.file.name,
        surveyId: surveyId.value
      }
    })
    const result = await mutate()

    if (result === null) {
      const error = new Error('No result from mutation')
      throw error
    }
    if (error.value) {
      throw error
    }
    const url = result.data?.gcpSignedPutUrl?.signed_url
    if (url === null || url === undefined) {
      const error = new Error('No url returned from mutation')
      throw error
    }
    this.putUrl = url
    this.fetchUrl = result.data?.gcpSignedPutUrl?.fetch_url ?? null
    this.mediaId = result.data?.gcpSignedPutUrl?.media_id ?? null

    return url
  }

  private _fileType() {
    const type = this.file.type
    if (type.includes('image')) { return 'image' }
    if (type.includes('video')) { return 'video' }
    if (type.includes('audio')) { return 'audio' }
    return null
  }
}
