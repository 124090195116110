<template>
  <div class="full-bleed -mx-12 h-1 bg-gray-medium opacity-80">
    <div class="h-full bg-space-orange" :style="[progressWidth]"></div>
  </div>
  </template>
<script setup>
  const { totalSlides, completedSlideIndices } = useNavigation()
  const slidesWithoutUploading = totalSlides - 2
  const progressWidth = computed(() => {
    return `width: ${Math.round((completedSlideIndices.value / slidesWithoutUploading) * 100)}%`
  })
</script>

<style>
.full-bleed {
  width: calc(100% + 6rem);
}
</style>