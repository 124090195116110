<template>
  <div class="flex justify-center flex-col items-center gap-y-2">
    <div v-if="back" class="cursor-pointer" @click="advance">
      <span class="font-monument text-sm text-gray-dark">↑ Previous Step</span>
    </div>
    <InputButton v-else :disabled="disabled" :start-button="start" @click="advance">
      {{ start ? 'Start' : 'Next →' }}
    </InputButton>
    <div
      v-if="!back"
      :class="[
        disabled ? 'opacity-0' : '',
        'hidden md:inline font-monument text-xs text-gray-dark'
      ]"
    >
      enter &#9166;
    </div>
  </div>
</template>
<script setup lang="ts">
const segment = useSegment()
const { fingerprint } = useFingerprint()
const { activeSlide } = useNavigation()
const props = defineProps({
  disabled: {
    type: Boolean,
    default: false
  },
  back: {
    type: Boolean,
    default: false
  },
  start: {
    type: Boolean,
    default: false
  }
})

function advance() {
  if (!props.disabled) {
    if (props.back) {
      activeSlide.value--
    } else {
      if (props.start) {
        segment.track('submission_started', {}, { traits: { visitorId: fingerprint.value } })
      }
      activeSlide.value++
    }
  }
}

</script>
