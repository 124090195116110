import log from 'consola'
import { Ref } from 'nuxt/dist/app/compat/capi'
import { Craft_Shape_Enum_Enum } from '~/utils/generated/graphql'

export interface MadLibOption {
  placeholder: string
  options: { label: string, suffix?: string, prefix?: string }[]
  prefix: string
  singularPrefix?: string
  suffix: string
  selectedIdx: number[] | null
  multiple: boolean,
  allowOther: boolean
  otherInput: string
}

const _activeMadLibIdx = () => useState<number>('activeMadLibIdx', () => -1)

export type MadLibMultiOptionValue = 'positive' | 'negative' | 'neutral'

export interface MadLibMultiOption {
  title: string
  selected: null | MadLibMultiOptionValue
}

const otherShapeInput = () => useState<null | string>('otherShapeInput', () => null)
const otherSizeInput = () => useState<null | string>('otherSizeInput', () => null)

const _options = () => useState<MadLibOption[]>('options', () => [
  {
    placeholder: 'how many',
    options: [
      { label: '1', suffix: 'object' },
      { label: '2', suffix: 'objects' },
      { label: '3', suffix: 'objects' },
      { label: '4', suffix: 'objects' },
      { label: '5+', suffix: 'objects' }
    ],
    prefix: 'I saw',
    suffix: 'objects',
    selectedIdx: null,
    multiple: false,
    allowOther: false,
    otherInput: ''
  },
  {
    placeholder: 'what shape',
    prefix: 'They were',
    singularPrefix: 'It was',
    suffix: 'shaped',
    options: _craftShapes,
    selectedIdx: null,
    multiple: false,
    allowOther: true,
    otherInput: ''
  },
  {
    placeholder: 'how far',
    prefix: 'At their closest, the UAPs were',
    singularPrefix: 'At its closest, the UAP was',
    suffix: 'distance / altitude',
    options: [
      { label: 'very close (< 50ft)', suffix: 'distant' },
      { label: 'close (within a football field)', suffix: 'of distance' },
      { label: 'medium (a few miles)', suffix: 'of distance' },
      { label: 'as far as the horizon', suffix: 'in distance' },
      { label: 'above the trees', suffix: 'in altitude' },
      { label: 'as high as a plane', suffix: 'in altitude' },
      { label: 'as high as a star ✨', suffix: 'in altitude' }
    ],
    selectedIdx: null,
    multiple: false,
    allowOther: false,
    otherInput: ''
  },
  {
    placeholder: 'how large',
    prefix: 'Viewed at arm\'s length, they were',
    singularPrefix: 'Viewed at arm\'s length, it was',
    options: [
      { label: 'star ✨' },
      { label: 'grain of rice' },
      { label: 'coin' },
      { label: 'tennis ball' },
      { label: 'basketball' },
      { label: 'massively' }
    ],
    suffix: 'sized',
    selectedIdx: null,
    multiple: false,
    allowOther: true,
    otherInput: ''
  },
  {
    placeholder: 'which patterns',
    prefix: 'They had',
    singularPrefix: 'It had',
    suffix: 'movement',
    options: [
      { label: 'hovering' },
      { label: 'floating around' },
      { label: 'straight and steady' },
      { label: 'circular' },
      { label: 'slowly descending' },
      { label: 'unpredictable, erratic' },
      { label: 'random, smooth' },
      { label: 'extremely fast' },
      { label: 'abrupt changes in direction' }
    ],
    multiple: true,
    selectedIdx: null,
    allowOther: false,
    otherInput: ''
  }
])

function _behaviorJsonInput() {
  const options = _options().value
  const otherFeatures = _otherFeatures().value
  const sensors = useSensorSlide().sensorOptions.value
  const otherSensorInput = useSensorSlide().otherSensorInput.value
  // short term anon submission tracking
  const { nameInput } = useNameSlide()
  const reportAnonymously = nameInput.value === ''
  // temporarily adding location PII to behavior json for tracking
  const locationPII = useLocationSlide().yesOrNoPII.value
  const isSensitiveLocation = locationPII === 'yes'

  // map the options to the insert object, setting the value to the values indicated in selectedIdx
  const features = options.map((opt) => {
    const prompt = opt.prefix + ' X ' + opt.suffix
    const { otherInput, allowOther, multiple } = opt
    let mapped: string | string[] = []

    const { selectedIdx: selected } = opt
    const otherInputValue = allowOther ? otherInput : null

    if (selected === null) {
      log.warn(`selectedIdx is null for option ${opt}`)
      mapped = []
    } else if (opt.multiple) {
      mapped = opt.options.map(a => a.label).filter((_, idx) => selected.includes(idx))
    } else {
      mapped = opt.options[selected[0]].label
    }

    return {
      prompt,
      options,
      multiple,
      selected: mapped,
      allowOther,
      customInput: otherInputValue
    }
  })

  const utmSource = (new URLSearchParams(window.location.search)).get('utm_source')
  const referer = document.referrer

  return {
    features,
    otherFeatures,
    sensors,
    otherSensorInput,
    utmSource,
    referer,
    reportAnonymously,
    isSensitiveLocation
  }
}

const _otherFeatures = () => useState<MadLibMultiOption[]>('otherFeatures', () => {
  return [
    {
      title: 'Have wings?',
      selected: null
    }, {
      title: 'Have rotors?',
      selected: null
    }, {
      title: 'Have an exhaust plume?',
      selected: null
    },
    {
      title: 'Make noise?',
      selected: null
    }
  ]
})

const _featureSlideValid = computed(() => {
  const features = _otherFeatures()
  return features.value.every(feature => feature.selected !== null)
})

const _behaviorSlideValid = computed(() => {
  const options = _options()
  return options.value.every(option => option.selectedIdx !== null || (option.otherInput !== undefined && option.otherInput?.trim().length > 0))
})

const _craftShapes = Object.keys(Craft_Shape_Enum_Enum)
  .filter(i => isNaN(Number(i)))
  .map(i => i.toUpperCase() as Craft_Shape_Enum_Enum)
  .filter((item) => {
    const unwanted = [
      Craft_Shape_Enum_Enum.Formation,
      Craft_Shape_Enum_Enum.Unknown,
      Craft_Shape_Enum_Enum.Other
    ]

    if (unwanted.includes(item)) {
      return false
    }

    return isNaN(Number(item))
  })
  .map((i) => { return { value: i, label: i.toLowerCase() } })
  .map((i) => {
    if (i.value === Craft_Shape_Enum_Enum.Changing) {
      return { label: i.label, suffix: 'shape' }
    }
    return i
  })

const _singular = () => computed(() => {
  return _options().value[0].selectedIdx?.includes(0)
})

// values for segment track events
const _craftCount = () => computed(() => {
  return _options().value[0].selectedIdx
})

const _craftShape = () => computed(() => {
  return _options().value[1].otherInput || _options().value[1].selectedIdx
})

const _craftDistance = () => computed(() => {
  return _options().value[2].selectedIdx
})

const _craftSize = () => computed(() => {
  return _options().value[3].otherInput || _options().value[3].selectedIdx
})

const _craftMovement = () => computed(() => {
  return _options().value[4].selectedIdx
})

export default function () {
  return {
    options: _options(),
    otherFeatures: _otherFeatures(),
    inputValid: _behaviorSlideValid,
    featureInputValid: _featureSlideValid,
    craftShapes: _craftShapes,
    activeMadLibIdx: _activeMadLibIdx(),
    insertInput: _behaviorJsonInput,
    singular: _singular(),
    craftCount: _craftCount(),
    craftShape: _craftShape(),
    craftDistance: _craftDistance(),
    craftSize: _craftSize(),
    craftMovement: _craftMovement()
  }
}
