import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-vue-v3'

async function _getFingerprint(): Promise<string | null> {
  log.time('fingerprint')

  const segment = useSegment()
  const anonId = await segment.$segment.user()

  _fingerprint().value = String(anonId.anonymousId())

  return String(anonId.anonymousId())
}

const _fingerprint = () => useState<string | null>('fingerprint', () => null)

export default function () {
  return {
    fingerprint: _fingerprint(),
    getFingerprint: _getFingerprint
  }
}
