import log from 'consola'

export const slideIsValid = function (idx: number): boolean {
  switch (idx) {
    case 1:
      return useLocationSlide().inputValid.value
    case 2:
      return useDateTimeSlide().inputValid.value
    case 3:
      return useStorySlide().inputValid.value
    case 4:
      return useBehavior().inputValid.value
    case 5:
      return useBehavior().featureInputValid.value
    case 6:
      return useWitnessCountSlide().inputValid.value
    case 7:
      return useSensorSlide().inputValid.value
    case 8:
      return useMediaSlide().inputValid.value
    case 9:
      // optional slide — validate if active or on following slide
      const { activeSlide } = useNavigation()
      return activeSlide.value >= 9
    case 10: {
      const { activeSlide } = useNavigation()
      return useNameSlide().inputValid.value && activeSlide.value >= 10
    }
    case 11:
      return false
    default:
      if (idx !== 0) {
        log.warn(`No handler for validating slide ${idx}`)
      }
      return false
  }
}
