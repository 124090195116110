const _useWitnessCountInput = () => useState<number | null>('witnessCountInput', () => null)
const _inputValid = computed(() => {
  const witnessCountInput = _useWitnessCountInput()
  return witnessCountInput.value !== null && witnessCountInput.value > 0
})

export default function () {
  return {
    witnessCountInput: _useWitnessCountInput(),
    inputValid: _inputValid
  }
}
