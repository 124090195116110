<template>
  <div class="flex flex-col items-center justify-center">
    <InputButton :disabled="disabled" @click="advance">
      Submit Sighting
    </InputButton>
  </div>
</template>
<script setup lang="ts">

const { activeSlide } = useNavigation()
const props = defineProps({
  disabled: {
    type: Boolean,
    default: false
  },
  back: {
    type: Boolean,
    default: false
  }
})

function advance() {
  if (!props.disabled) {
    if (props.back) {
      activeSlide.value--
    } else {
      activeSlide.value++
    }
  }
}

</script>
