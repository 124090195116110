<template>
  <div :class="['w-full h-[100dvh] overflow-x-hidden sm:overflow-x-auto sm:min-h-screen flex flex-col md:flex-row', { 'onboarding-bg': activeSlide === 0 && !route.params.uid}]">
    <div v-if="activeSlide === 0 && isMobile" class="flex justify-between p-4 pl-3 transparent-glass">
      <a href="//enigmalabs.io" target="_blank">
        <img src="/wordmark-white.svg" alt="Enigma Labs">
      </a>
      <div v-if="uploading">
        <span class="font-monument text-xs text-space-orange">Uploading report</span>
      </div>
      <div v-else-if="submitted">
        <span class="font-monument text-xs">Submitted</span>
      </div>
      <ControlNextSlide v-else-if="back" back start />
    </div>
    <div :class="['flex-col md:basis-1/2 flex p-4 md:p-6 md:px-12 md:max-h-screen', { 'flex-grow': singlePane }]">
      <div v-if="!(activeSlide === 0 && isMobile)" class="flex justify-between pb-3 md:pb-6 pr-1">
        <!-- <svg src="/wordmark.svg" alt="Enigma Labs"></svg> -->
        <a href="//enigmalabs.io" target="_blank">
          <img src="/wordmark.svg" alt="Enigma Labs">
        </a>
        <div v-if="uploading">
          <span class="font-monument text-xs text-space-orange">Uploading report</span>
        </div>
        <div v-else-if="submitted">
          <span class="font-monument text-xs">Submitted</span>
        </div>
        <ControlNextSlide v-else-if="back" back start />
      </div>
      <div v-if="isSubmissionFlow" class="pb-3">
        <ControlProgress />
      </div>
      <slot name="left" />
      <div v-if="forward" class="pt-6">
        <ControlNextSlide data-test="Next Slide" :disabled="props.forwardDisabled" :start="props.start" />
      </div>
      <div v-if="submit" class="pt-4">
        <div v-if="invalidSlideIndices.length > 0 && !onlyLastSlideInvalid" class="text-red-500 p-2">
          Please finish all required fields.
          {{ invalidSlideIndices.length }} {{ invalidSlideIndices.length > 1 ? 'slides are' : 'slide is' }} incomplete.
        </div>
        <InputCheckbox
          v-model="termsAgreement"
          class="text-sm inline-flex ml-2 mb-5"
          has-html-label
          is-terms-checkbox
        >
          <span class="pl-2 font-monument leading-5">
            By submitting, you agree to Enigma's <a class="text-space-orange underline" href="https://enigmalabs.io/terms" target="_blank">terms of service</a> and <a class="text-space-orange underline" href="https://enigmalabs.io/privacy" target="_blank">privacy policy</a>.
          </span>
        </InputCheckbox>
        <ControlSubmit :disabled="!allSlidesValid || !termsAgreement" />
      </div>
    </div>
    <slot name="right" />
  </div>
</template>
<script setup lang="ts">
const { allSlidesValid, invalidSlideIndices, termsAgreement, activeSlide } = useNavigation()
const { isMobile } = useDevice()
const route = useRoute()
const onlyLastSlideInvalid = invalidSlideIndices.value.length === 1 && invalidSlideIndices.value[0] === 9

const props = defineProps({
  forward: {
    type: Boolean,
    default: () => true
  },
  back: {
    type: Boolean,
    default: () => false
  },
  start: {
    type: Boolean,
    default: () => false
  },
  forwardDisabled: {
    type: Boolean,
    default: () => false
  },
  submit: {
    type: Boolean,
    default: () => false
  },
  uploading: {
    type: Boolean,
    default: () => false
  },
  submitted: {
    type: Boolean,
    default: () => false
  },
  singlePane: {
    type: Boolean,
    default: () => false
  }
})

const isSubmissionFlow = computed(() => {
  return (!props.submitted && !props.start && !props.uploading)
})

</script>
<style>
@media (max-width: 768px) {
  .onboarding-bg {
    background-image: url('/img/clouds.png');
    background-size: cover;
    background-position: right;
  }

  .transparent-glass {
    border-color: #fafafa;
    color: #fafafa;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
  }
}
</style>