const _useNameInput = () => useState<string>('nameInput', () => '')
const _useSubmitAnonymously = () => useState<boolean>('submitAnonymously', () => false)
const _phoneNumberInput = () => useState<string | null>('phoneNumberInput', () => null)
const _emailInput = () => useState<string | null>('emailInput', () => null)
const _phoneNumberValid = () => useState<boolean>('phoneNumberValid', () => false)
const _phoneNumberFull = () => useState<string>('phoneNumberFull', () => '')
const _smsOptIn = () => useState<boolean>('smsOptIn', () => false)
const _emailOptIn = () => useState<boolean>('emailOptIn', () => false)

const _nameInputValid = computed(() => {
  const submitAnonymously = _useSubmitAnonymously().value
  const phoneNumber = _phoneNumberInput().value
  const email = _emailInput().value
  return !!phoneNumber || !!email || submitAnonymously
})

export default function () {
  return {
    nameInput: _useNameInput(),
    submitAnonymously: _useSubmitAnonymously(),
    phoneNumberInput: _phoneNumberInput(),
    emailInput: _emailInput(),
    emailOptIn: _emailOptIn(),
    phoneNumberValid: _phoneNumberValid(),
    phoneNumberFull: _phoneNumberFull(),
    smsOptIn: _smsOptIn(),
    inputValid: _nameInputValid
  }
}
